import React from "react"
import PrimaryButton from "./primary-button.component"
import { Link } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"

const Job = ({ location, title, slug, _rawShortDescription }) => {
  return (
    <div className="job">
      <div className="job__content">
        <h3 className="job__location">{location}</h3>
        <h2 className="heading__secondary heading__secondary--grey job__title">
          {title}
        </h2>
        <BlockContent
          className="paragraph job__description"
          blocks={_rawShortDescription}
          renderContainerOnSingleChild={true}
        />
        <Link to={slug.current}>
          <PrimaryButton label="Bekijk hier de vacature" />
        </Link>
      </div>
    </div>
  )
}

export default Job
