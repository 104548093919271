import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Layout from "./../components/layout.component"
import Service from "./../components/service.component"
import Gallery from "./../components/gallery.component"
import Job from "./../components/job.component"
import Form from "./../components/form.component"
import Background from "./../images/background.jpg"
import PrimaryButton from "./../components/primary-button.component"
import BlockContent from "@sanity/block-content-to-react"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query page {
      allSanityCompany {
        nodes {
          title
          slogan
          logo {
            asset {
              fluid {
                src
              }
            }
          }
        }
      }
      allSanityJobs {
        edges {
          node {
            id
            location
            title
            shortDescription {
              _key
              _type
              style
              list
            }
            slug {
              current
              _key
              _type
            }
            longDescription {
              _key
              _type
              list
              style
              sanityChildren {
                _key
                _type
                marks
                text
              }
            }
            _rawLongDescription(resolveReferences: { maxDepth: 10 })
            _rawShortDescription(resolveReferences: { maxDepth: 10 })
          }
        }
      }
      allSanityAbout {
        nodes {
          title
          description {
            _key
            _type
            list
            style
            sanityChildren {
              _key
              _type
              marks
              text
            }
          }
          id
          _rawDescription
        }
      }
      allSanityService {
        nodes {
          title
          photo {
            asset {
              url
            }
          }
          description {
            _key
            list
            _type
            style
            sanityChildren {
              _key
              _type
              marks
              text
            }
          }
          id
          _rawDescription
        }
      }
      allSanityGallery {
        nodes {
          photos {
            asset {
              url
            }
          }
          name
          id
        }
      }
    }
  `)

  const renderServicesSection = () => {
    const shouldRender = data.allSanityService.nodes.length > 0
    if (!shouldRender) return null

    return (
      <section className="services" id="services">
        <div className="services__content container">
          <h2 className="heading__secondary__alternative">Onze Waarden</h2>
          <div className="services__items">
            {data.allSanityService.nodes.map(service => (
              <Service
                key={service.id}
                label={service.title}
                path={service.photo.asset.url}
              >
                <BlockContent
                  className="paragraph service__content"
                  blocks={service._rawDescription}
                  renderContainerOnSingleChild={true}
                />
              </Service>
            ))}
          </div>
        </div>
      </section>
    )
  }

  const renderGallerySection = () => {
    const shouldRender = data.allSanityGallery.nodes.length > 0
    if (!shouldRender) return null

    return (
      <section className="implementation" id="gallery">
        <div className="implementation__content container">
          <h2 className="heading__secondary__alternative implementation__title">
            Realisaties
          </h2>
          <Gallery nodes={data.allSanityGallery.nodes} key={1} />
        </div>
      </section>
    )
  }

  const renderJobsSection = () => {
    const shouldRender = data.allSanityJobs.edges.length > 0
    if (!shouldRender) return null

    return (
      <section className="jobs" id="jobs">
        <div className="jobs__content container">
          <h2 className="heading__secondary__alternative jobs__title">
            Vacatures
          </h2>
          <div className="jobs__items">
            {data.allSanityJobs.edges.map(job => (
              <Job {...job.node} key={job.node.id} />
            ))}
          </div>
        </div>
      </section>
    )
  }

  return (
    <Layout>
      <div className="overlay">
        <img className="overlay__image" src={Background} alt="background" />
        <div className="overlay__heading">
          <h1 className="heading__primary overlay__title">
            {data.allSanityCompany.nodes[0].title}
          </h1>
          <h2 className="heading__secondary overlay__subtitle">
            {data.allSanityCompany.nodes[0].slogan}
          </h2>
          <AnchorLink to="#about">
            <PrimaryButton label="Over ons" />
          </AnchorLink>
        </div>
      </div>

      <section className="about" id="about">
        <div className="about__content container">
          <div className="about__left">
            <h2 className="heading__secondary__alternative">
              {data.allSanityAbout.nodes[0].title}
            </h2>
            <BlockContent
              className="paragraph about__text"
              blocks={data.allSanityAbout.nodes[0]._rawDescription}
              renderContainerOnSingleChild={true}
            />
          </div>
          <div className="about__right">
            <img
              src={data.allSanityCompany.nodes[0].logo.asset.fluid.src}
              alt="logo"
              className="about__image"
            />
          </div>
        </div>
      </section>

      {renderServicesSection()}
      {renderGallerySection()}
      {renderJobsSection()}
    </Layout>
  )
}

export default IndexPage
