import React from "react"

const Service = ({ label, path, children }) => (
  <div className="service">
    <div className="service__wrapper">
      <img src={path} alt={label} className="service__image" />
    </div>
    <h2 className="heading__secondary service__title">{label}</h2>
    {/* <p className="paragraph service__content"></p> */}
    {children}
  </div>
)

export default Service
