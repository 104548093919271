import React, { useState } from "react"
import PrimaryButton from "./primary-button.component"

const Gallery = ({ nodes }) => {
  const availableCategories = [{ id: 1, name: "All" }].concat(
    nodes.map(item => {
      return { id: item.id, name: item.name }
    })
  )

  const availableItems = []
  nodes.forEach(item => {
    const categoryId = item.id
    const name = item.name
    item.photos.forEach(photo => {
      availableItems.push({ categoryId, name, path: photo.asset.url })
    })
  })

  const [items] = useState(availableItems)
  const [filteredItems, setFilteredItems] = useState(availableItems)

  const [categories] = useState(availableCategories)

  const renderImage = item => {
    return (
      <div className="gallery__item" key={item.id}>
        <img src={item.path} alt={item.name} className="gallery__image" />
      </div>
    )
  }

  const renderCategory = category => {
    return (
      <PrimaryButton
        key={category.id}
        onClick={() => filterItems(category.id)}
        name="button button__primary button--small"
        label={category.name}
      />
    )
  }

  const filterItems = categoryId => {
    const category = categories.find(el => el.id === categoryId)
    const isAll = category && category.name.toLowerCase() === "all"
    if (isAll) {
      setFilteredItems(items)
    } else {
      const filteredItems = items.filter(el => el.categoryId === categoryId)
      setFilteredItems(filteredItems)
    }
  }

  return (
    <div className="gallery">
      <div className="gallery__filter">
        {categories.map(category => renderCategory(category))}
      </div>
      <div className="gallery__content">
        {filteredItems.map(item => renderImage(item))}
      </div>
    </div>
  )
}

export default Gallery
